import React from "react";
// eslint-disable-next-line
import cnConsole from "../utils/cnConsole";

const Pregunta = ({
  numberOfItems,
  title,
  onClick,
  hasError,
  value,
  errorText,
}) => {
  return (
    <div className="pregunta">
      <h3 className="pregunta__title">{title}</h3>
      <ul className="pregunta__values">
        {[...Array(numberOfItems).keys()].map((counter, index) => (
          <li
            key={`carita-${index}`}
            className={`pregunta__value ${
              index === value ? "pregunta__value--selected" : ""
            } `}
          >
            <button
              className="pregunta__button"
              aria-pressed="false"
              aria-label={`value option ${counter}`}
              onClick={() => {
                onClick(index);
              }}
            >
              <img
                src={require(`../assets/images/${counter}.svg`)}
                alt={counter}
                className="pregunta__image"
              />
            </button>
          </li>
        ))}
      </ul>
      {hasError && <div className="pregunta__error">{errorText}</div>}
    </div>
  );
};

export default Pregunta;
