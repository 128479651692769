import React, { useRef, useEffect, useContext, useState } from 'react';
import { HeaderContext } from '../../context/HeaderContext';
import { GlobalContext } from '../../context/GlobalContext';
import { Link } from 'react-router-dom';
import Pregunta from '../Pregunta';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import ScrollTop from '../common/ScrollTop';
// eslint-disable-next-line
import cnConsole from '../../utils/cnConsole';
import { saveCuestionary } from '../../services/SaveCuestionary';

const startState = { autoAlpha: 0, y: 50 };

const Cuestionario = ({ show, data }) => {
  //refs
  const nodeRef = useRef(null);

  // context & state
  const globalContext = useContext(GlobalContext);
  const headerContext = useContext(HeaderContext);
  const [currentTexts, setCurrentTexts] = useState(data);
  const [respuestas, setRespuestas] = useState([]);
  const [errores, setErrores] = useState([]);

  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(true);
      headerContext.setLaguageShown(true);
      headerContext.setHorizontalLogo(true);
      headerContext.setHasShadow(false);
      headerContext.setBackgroundImage(null);
      globalContext.setAppScroll('app--scroll');
    }
    // eslint-disable-next-line
  }, [show]);

  // i10n texts
  useEffect(() => {
    if (data) {
      setCurrentTexts(
        globalContext.language === 'es' ? data : data.translation
      );
      const emptyAnwers = currentTexts.preguntas.preguntas.map((item) => false);
      setRespuestas(emptyAnwers);
      setErrores(emptyAnwers);
    }
    // eslint-disable-next-line
  }, [data, globalContext.language]);

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <section ref={nodeRef} className='page page--custionario'>
        <ScrollTop />
        {currentTexts.preguntas.preguntas.map((item, index) => {
          return (
            <Pregunta
              key={`pregunta-${index}`}
              onClick={(value) => {
                const nuevasRespuestas = [...respuestas];
                nuevasRespuestas[index] = value;
                setRespuestas(nuevasRespuestas);
              }}
              numberOfItems={item.valor.length}
              title={item.pregunta}
              value={respuestas[index]}
              hasError={errores[index]}
              errorText={globalContext.getStaticTextByKey('error')}
            />
          );
        })}
        {errores.map((error) => error).some((error) => error) && (
          <div className='pregunta__error'>
            {globalContext.getStaticTextByKey('senderror')}
          </div>
        )}
        <div className='navigation'>
          <Link
            to='social-share'
            className='navigation__button navigation__button--next'
            onClick={(event) => {
              const noHasRespondido = respuestas.map(
                (respuesta) => respuesta === false
              );
              setErrores(noHasRespondido);
              const hayErrores = noHasRespondido.some((respuesta) => respuesta);
              if (hayErrores) {
                event.preventDefault();
                return;
              }
              // salvamos cuestionario
              saveCuestionary({
                q1: respuestas[0],
                q2: respuestas[1],
                q3: respuestas[2],
                q4: respuestas[3],
              });
            }}
          >
            {globalContext.getStaticTextByKey('send')}
          </Link>
        </div>
      </section>
    </Transition>
  );
};

export default Cuestionario;
