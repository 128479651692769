import React from "react";
import { Transition } from "react-transition-group";
import { useRef } from "react";
import gsap from "gsap/gsap-core";

import cross from "../../assets/images/error-cross.svg";
import cnConsole from "../../utils/cnConsole";

export default function ({ show }) {
  const errorRef = useRef();
  cnConsole(show);
  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={errorRef}
      onEnter={() => {
        gsap.set(errorRef.current, { opacity: 0 });
      }}
      addEndListener={(done) => {
        gsap.to(errorRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          onComplete: done,
        });
      }}
    >
      <div className="error" ref={errorRef}>
        <div className="error__content">
          <figure className="error__animation">
            <img src={cross} alt="" className="error__image" />
            <figcaption className="error__text">
              404 No se encuentra la página solicitada
            </figcaption>
          </figure>
          <div className="language__navigation navigation">
            <a className="navigation__button navigation__button--next" href="/">
              VOLVER AL INICIO
            </a>
          </div>
        </div>
      </div>
    </Transition>
  );
}
