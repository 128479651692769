import React, { useState, createContext } from 'react';
import { languageIso } from '../utils/language';

import texts from '../texts.json';

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [initialLoad, setInitialLoad] = useState(false);
  const [language, setLanguage] = useState(languageIso());
  const [appData, setAppData] = useState(null);
  const [staticText, setStaticText] = useState(texts);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [appScroll, setAppScroll] = useState(false);
  const [appIsSalas, setAppIsSalas] = useState(false);

  const getStaticTextByKey = (key) => {
    return staticText[language][key];
  };

  return (
    <GlobalContext.Provider
      value={{
        initialLoad,
        setInitialLoad,
        language,
        setLanguage,
        appData,
        setAppData,
        staticText,
        setStaticText,
        getStaticTextByKey,
        isModalVisible,
        setIsModalVisible,
        appScroll,
        setAppScroll,
        appIsSalas,
        setAppIsSalas,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
