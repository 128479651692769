import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Analitycs from "./common/Analitycs";
import ColorBand from "./common/ColorBand";
import Header from "./common/Header";
import { MAIN_QUERY } from "../queries/MainQuery";
import { Telefonica } from "./common/Telefonica";
import { Loading } from "./common/Loading";
import { Error } from "./common/Error";
import { GlobalContext } from "../context/GlobalContext";
// eslint-disable-next-line
import cnConsole from "../utils/cnConsole";

import Language from "./pages/Language";
import Instrucciones from "./pages/Instrucciones";
import SeleccionSala from "./pages/SeleccionSala";
import SocialShare from "./pages/SocialShare";
import Sala from "./pages/Sala";
import Cuestionario from "./pages/Cuestionario";
import Modal from "./common/Modal";
import { useWindowScroll } from "../hooks/useWindowScroll";
import NoMatch from "./pages/NoMatch";

let initialHeaderSize = document.documentElement.style.getPropertyValue(
  "--header__height"
);

export default function MyRouter() {
  // Cargamos los datos
  const { loading, error, data } = useQuery(MAIN_QUERY);

  //----> Guardar los datos en el contexto global
  const globalContext = useContext(GlobalContext);
  useEffect(() => {
    //
    if (data) {
      cnConsole("! Data completa", data);
      globalContext.setAppData(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const appRef = useRef(null);
  useEffect(() => {
    const setAppHeightMinContent = () => {
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      appRef.current.style.height = globalContext.appScroll
        ? "auto"
        : `${vh}px`;
    };
    window.addEventListener("resize", setAppHeightMinContent);
    setAppHeightMinContent();
    return () => {
      window.removeEventListener("resize", setAppHeightMinContent);
    };
  }, [globalContext.appScroll]);

  const headerRef = useRef(null);

  const scroll = false;
  // cuando el scroll ha recorrido diff + colobanHe
  useEffect(() => {
    if (!scroll) {
      return () => {};
    }
    const colorBandHeight = 20;
    const initialHeightInPixels = (window.outerHeight * 20) / 100;
    const finalHeightInPixels = (window.outerHeight * 5) / 100;
    const diff = initialHeightInPixels - finalHeightInPixels;
    // Mayor que la banda de color y menor que la diferencia
    if (scroll.y <= colorBandHeight) {
      document.documentElement.style.setProperty(
        "--height__header",
        `${initialHeightInPixels}px`
      );
    }
    if (scroll.y > colorBandHeight && scroll.y <= diff) {
      const ratio = (scroll.y - colorBandHeight) / diff;
      const decrementAmount = diff * ratio;
      cnConsole(scroll.y, diff, ratio);
      document.documentElement.style.setProperty(
        "--height__header",
        `${initialHeightInPixels - decrementAmount}px`
      );
      window.scrollTo(0, scroll.y);
    }
    if (scroll.y > diff + colorBandHeight) {
      document.documentElement.style.setProperty(
        "--height__header",
        `${initialHeightInPixels - diff}px`
      );
    }
  }, [scroll]);
  // Mostramos cargando mientras cargamos los textos staticos y los del graphql
  if (loading) {
    return (
      <div className={`app__container ${globalContext.appScroll}`} ref={appRef}>
        <Router>
          <ColorBand />
          <Header ref={headerRef} />
          <Loading className="loading--main-loader" show={loading} />
        </Router>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`app__container ${globalContext.appScroll}`} ref={appRef}>
        <Router>
          <ColorBand />
          <Header ref={headerRef} />
          <Error className="loading--main-loader" error={error} />
        </Router>
      </div>
    );
  }

  return (
    <div className={`app__container ${globalContext.appScroll}`} ref={appRef}>
      <Router>
        {data.notification.edges.length !== 0 && (
          <Modal
            isVisible={globalContext.isModalVisible}
            data={
              data.notification.edges.length !== 0
                ? data.notification.edges[0].node
                : null
            }
          />
        )}
        <ColorBand />
        <Header ref={headerRef} />
        <Analitycs />
        {data && (
          <Switch>
            <Route exact path="/">
              {({ match }) => {
                return (
                  <Language
                    show={match !== null}
                    data={data.presentation.edges[0].node}
                    onNext={() => {
                      // comprobamos notificiones si no hay a la siguiente pagina si hay mostramos notificaciones
                      globalContext.setIsModalVisible(true);
                    }}
                  />
                );
              }}
            </Route>
            <Route path="/instrucciones">
              {({ match }) => (
                <Instrucciones
                  show={match !== null}
                  data={data.instruccions.edges[0].node}
                />
              )}
            </Route>
            <Route path="/seleccion-sala">
              {({ match }) => (
                <SeleccionSala show={match !== null} data={data.rooms} />
              )}
            </Route>
            <Route path="/sala/:slug">
              {({ match }) => (
                <Sala show={match !== null} data={data.rooms.edges} />
              )}
            </Route>
            <Route path="/cuestionario/">
              {({ match }) => (
                <Cuestionario
                  show={match !== null}
                  data={data.cuestionario.edges[0].node}
                />
              )}
            </Route>
            <Route path="/social-share/">
              {({ match }) => (
                <SocialShare
                  show={match !== null}
                  data={data.spotify.edges[0].node}
                />
              )}
            </Route>
            <Route path="*">
              {({ match }) => <NoMatch show={match !== null} />}
            </Route>
          </Switch>
        )}
        <Telefonica />
      </Router>
    </div>
  );
}
