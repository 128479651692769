import React, { forwardRef } from "react";

export default forwardRef(function LanguageOption(
  { image, iso, isSelected, name, onClick },
  ref
) {
  return (
    <li
      ref={ref}
      className={`language-selector__item${
        isSelected ? " language-selector__item--selected" : ""
      }`}
    >
      <button
        className="language-selector__button"
        onClick={() => onClick(iso)}
      >
        {iso}{" "}
        <img src={image} alt={name} className="language-selector__image" />
      </button>
    </li>
  );
});
