import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga";
import history from "../../utils/history";
import { ANALITYCSID } from "../../config/app";
import isProduction from "../../utils/isProduction";
import { GlobalContext } from "../../context/GlobalContext";
import { useLocation } from "react-router-dom";

const Analitycs = () => {
  const context = useContext(GlobalContext);

  const routerLocation = useLocation();

  useEffect(() => {
    ReactGA.initialize(ANALITYCSID, {
      debug: !isProduction(),
    });
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
    if (!context.initialLoad) {
      ReactGA.pageview(routerLocation.pathname);
      context.setInitialLoad(true);
    }
    // eslint-disable-next-line
  }, []);
  return <></>;
};

export default Analitycs;
