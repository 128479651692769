// eslint-disable-next-line
import cnConsole from './cnConsole';

export const convertMilisecondsToSeconds = (position) => {
  var minutes = Math.floor(position / 60);
  var seconds = (position % 60).toFixed(0);
  return (
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  );
};
