import React, { useEffect, useRef } from 'react';

import play from '../../assets/images/play.svg';
import pause from '../../assets/images/pause.svg';
import { useState } from 'react';
import cnConsole from '../../utils/cnConsole';
import { convertMilisecondsToSeconds } from '../../utils/time';
import BounceLoader from 'react-spinners/BounceLoader';

let playPromise = null;
let firstLoad = true;
let isDraging = false;

const AudioPlayer = ({ audiofile, autoplay = false, title }) => {
  const audioRef = useRef(null);

  const [position, setPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateAudio = (event) => {
    if (!isDraging) {
      // cnConsole("! updateAudio:", audioRef.current.currentTime);
      setPosition(audioRef.current.currentTime);
    }
  };

  useEffect(() => {
    cnConsole('! audiofile: ', audiofile);
    // Cuando se puede se asigna las propiedades del audio
    // Ha cambiado todo ponemos el audio en stop
    setIsPlaying(false);
    const initAudio = (event) => {
      if (audioRef.current.duration && firstLoad) {
        cnConsole('!!! initAudio');
        setIsLoading(false);
        setEndPosition(audioRef.current.duration);
        firstLoad = false;
        // si tenemos autoplay arrancamos.
        if (autoplay) {
          playAudio();
        }
        audioRef.current.addEventListener('timeupdate', updateAudio);
      }
    };
    audioRef.current.addEventListener('canplaythrough', initAudio);
    audioRef.current.addEventListener('ended', endAudio);
    // Al salir cerramos eventos creados
    return () => {
      firstLoad = true;
      audioRef.current.pause();
      audioRef.current.removeEventListener('timeupdate', updateAudio);
      audioRef.current.removeEventListener('ended', endAudio);
      // eslint-disable-next-line
      audioRef.current.removeEventListener('canplaythrough', initAudio);
    };
    // eslint-disable-next-line
  }, [audiofile]);

  const playAudio = () => {
    cnConsole('! playAudio');
    playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          playPromise = undefined;
          setIsPlaying(true);
        })
        .catch((Error) => {
          cnConsole('! Audio Error, posible cors', Error.message);
        });
    }
  };

  const endAudio = () => {
    audioRef.current.currentTime = 0;
    setPosition(audioRef.current.currentTime);
    pauseAudio();
  };

  const updateAudioPosition = (event) => {
    audioRef.current.currentTime = event.target.value;
    // cnConsole(
    //   "updateAudioPosition: audioRef.current.currentTime",
    //   audioRef.current.currentTime,
    //   " range value:",
    //   event.target.value
    // );
    setPosition(event.target.value);
  };

  const pauseAudio = () => {
    cnConsole('! pauseAudio');
    playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // Audio started ready to do things
          audioRef.current.pause();
          playPromise = undefined;
          setIsPlaying(false);
        })
        .catch((Error) => {
          cnConsole('! Audio Error, posible cors', Error.message);
        });
    }
  };

  return (
    <div className='audioplayer'>
      <h3 className='audioplayer__subtitle'>{title}</h3>
      <div className='audioplayer__controls'>
        <audio src={audiofile} ref={audioRef} />
        <BounceLoader
          className='audioplayer__playpause'
          css=''
          size={30}
          color={'#ffffff'}
          loading={isLoading}
        />
        {!isLoading && (
          <button
            className='audioplayer__playpause'
            aria-label='play'
            onClick={() => (isPlaying ? pauseAudio() : playAudio())}
          >
            <img src={isPlaying ? pause : play} alt='play button' />
          </button>
        )}
        <input
          type='range'
          aria-label='audio position'
          className='audioplayer__slider'
          min='0'
          value={position}
          max={endPosition}
          onChange={updateAudioPosition}
          onPointerDown={() => {
            isDraging = true;
            pauseAudio();
            cnConsole(audioRef.current.seekable);
          }}
          onPointerUp={(event) => {
            playAudio();
            isDraging = false;
          }}
        />
      </div>
      <div className='audioplayer__time'>
        {convertMilisecondsToSeconds(position)} /{' '}
        {convertMilisecondsToSeconds(endPosition)}
      </div>
    </div>
  );
};

export default AudioPlayer;
