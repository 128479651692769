import React, { useContext } from 'react';

import { HeaderContext } from '../../context/HeaderContext';
import { GlobalContext } from '../../context/GlobalContext';
import cnConsole from '../../utils/cnConsole';

function ColorBand({ data }) {
  const headerContext = useContext(HeaderContext);
  //
  const globalContext = useContext(GlobalContext);

  // TODO: Sacar de aqui de momento lo mantenemos por las prisas Guardar los datos en el contexto global
  if (data) {
    cnConsole(data);
    globalContext.setAppData(data);
  }

  return (
    <div
      className={`color-band ${
        headerContext.backgroundImage ? 'color-band--sala' : ''
      }`}
    >
      <div className='color-band__red'></div>
      <div className='color-band__red'></div>
      <div className='color-band__red'></div>
      <div className='color-band__red'></div>
    </div>
  );
}
export default ColorBand;
