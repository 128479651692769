import React, { useRef, useEffect, useContext, useState } from 'react';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { HeaderContext } from '../../context/HeaderContext';
import { GlobalContext } from '../../context/GlobalContext';
import { Link, useParams, useLocation } from 'react-router-dom';

import iconoBotones from '../../assets/images/icono-botones.png';
import iconoBotones2x from '../../assets/images/icono-botones@2x.png';
import iconoBotones3x from '../../assets/images/icono-botones@3x.png';
import nextArrow from '../../assets/images/arrow-next.svg';

import background from '../../assets/images/imagen-cabecera.png';
import AudioPlayer from '../audioPlayer/AudioPlayer';
import cnConsole from '../../utils/cnConsole';
import ScrollTop from '../common/ScrollTop';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { sanitizeWithAllowTags } from '../../utils/clearWpParagraph';

const startState = { autoAlpha: 0, y: 50 };

const Sala = ({ show, data }) => {
  //refs
  const nodeRef = useRef(null);

  // context & state
  const globalContext = useContext(GlobalContext);
  const headerContext = useContext(HeaderContext);
  const [currentTexts, setCurrentTexts] = useState(data);
  const [nextPage, setNextPage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [realRoomIndex, setRealRoomIndex] = useState(0);
  const [currentRoomData, setCurrentRoomData] = useState(0);
  const [audioFile, setAudioFile] = useState(false);
  const [galeria, setGaleria] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [activeData, setActiveData] = useState(
    data.filter((item) => item.node.room_data.activa)
  );

  // hooks
  const params = useParams();
  const location = useLocation();

  // Setup header
  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(true);
      headerContext.setLaguageShown(true);
      headerContext.setHorizontalLogo(true);
      headerContext.setHasShadow(true);
      headerContext.setBackgroundImage(background);
      globalContext.setAppScroll('app--salas');
    }
    // eslint-disable-next-line
  }, [show]);

  // i10n texts
  useEffect(() => {
    const findBySlug = (room) => room.node.slug === params.slug;
    const hasNextRoom = (currentRoomIndex) => activeData[currentRoomIndex + 1];
    if (activeData) {
      setIsLastPage(false);
      const currentRoomIndex = activeData?.findIndex(findBySlug);
      setRealRoomIndex(data?.findIndex(findBySlug));
      if (currentRoomIndex >= 0 && hasNextRoom(currentRoomIndex)) {
        // actualizamos states
        setCurrentIndex(currentRoomIndex);
        setCurrentRoomData(activeData[currentRoomIndex].node);
        const nextSlug = hasNextRoom(currentRoomIndex)?.node.slug;
        const nextPageSlug = nextSlug ? `/sala/${nextSlug}` : '/cuestionario';

        setNextPage(nextPageSlug);
      } else {
        setIsLastPage(true);
        setCurrentIndex(currentRoomIndex);
        setCurrentRoomData(activeData[currentRoomIndex].node);
      }
      setCurrentTexts(
        globalContext.language === 'es' ? activeData : activeData.translation
      );
    }
    // eslint-disable-next-line
  }, [activeData, location.pathname]);

  useEffect(() => {
    if (currentRoomData) {
      setGaleria(currentRoomData.room_data.roomGalery);
      const image = currentRoomData.featuredImage.node.sourceUrl;
      if (image) {
        headerContext.setBackgroundImage(image);
      }
      // si es la ultima pagina indicar
      if (currentIndex >= activeData.length - 1) {
        setIsLastPage(true);
      }
      const languajeobj =
        globalContext.language === 'es'
          ? currentRoomData
          : currentRoomData.translation;
      cnConsole(currentRoomData);
      setCurrentTexts(languajeobj);
      setAudioFile(languajeobj?.room_data.roomAudio.mediaItemUrl);
    }
    // eslint-disable-next-line
  }, [currentRoomData, globalContext.language]);

  // Methods

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      key={location.key}
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <>
        <ScrollTop />
        <section ref={nodeRef} className='page page--sala'>
          <div className='sala__counter button-round'>{realRoomIndex + 1}</div>
          <div className='subtitle__full-center'>
            <h2 className='subtitle'>{currentTexts?.title}</h2>
          </div>
          <div
            className='sala__content'
            dangerouslySetInnerHTML={{
              __html: sanitizeWithAllowTags(currentTexts?.content),
            }}
          ></div>
          {galeria && (
            <ul className='sala__galeria'>
              {galeria.map((image) => {
                return (
                  <li className='sala__imagen' key={image.id}>
                    <LazyLoadImage src={image.sourceUrl} />
                  </li>
                );
              })}
            </ul>
          )}

          <div className='sala__sticky'>
            <div
              className={`navigation sala__navigation ${
                isLastPage ? 'sala__navigation--centered' : ''
              }`}
            >
              {!isLastPage && (
                <>
                  <Link
                    className='navigation__button navigation__button--all'
                    onClick={() => {}}
                    to='/seleccion-sala'
                  >
                    <img
                      src={`${iconoBotones}`}
                      srcSet={`${iconoBotones2x} 2x,
            ${iconoBotones3x} 3x`}
                      className='icono-botones'
                      alt=''
                    ></img>
                    {globalContext.getStaticTextByKey('choose')}
                  </Link>
                  <Link
                    className='navigation__button navigation__button--next'
                    onClick={() => {}}
                    to={nextPage}
                  >
                    {globalContext.getStaticTextByKey('next_room')}
                    <img
                      src={`${nextArrow}`}
                      className='icono-botones'
                      alt=''
                    ></img>
                  </Link>
                </>
              )}
              {isLastPage && (
                <Link
                  className='navigation__button navigation__button--next navigation__button--centered'
                  onClick={() => {}}
                  to='/cuestionario'
                >
                  {globalContext.getStaticTextByKey('end')}
                  <img
                    src={`${nextArrow}`}
                    className='icono-botones'
                    alt=''
                  ></img>
                </Link>
              )}
            </div>
            {audioFile && (
              <AudioPlayer
                title={globalContext.getStaticTextByKey('player')}
                audiofile={audioFile}
                autoplay={false}
              />
            )}
          </div>
        </section>
      </>
    </Transition>
  );
};

export default Sala;
