import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';

import es from '../../assets/images/bandera-espa-a.svg';
import en from '../../assets/images/bandera-inglesa.svg';
import LanguageOption from './LanguageOption';
import { useRef } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { useContext } from 'react';

let isFirstLoad = true;

export default function LanguageSelector({ className }) {
  const globalContext = useContext(GlobalContext);
  // const nodeRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const esRef = useRef(null);
  const enRef = useRef(null);
  const selectorRef = useRef(null);
  // Al a abrir cerrar desencadenar animacines con greenshock en caso de ser necesario
  useEffect(() => {
    let time = 0.2;

    if (isFirstLoad) {
      // al ser la primera vez no mostramos animacion
      isFirstLoad = false;
      time = 0;
    }

    const hideRef =
      globalContext.language === 'es' ? enRef.current : esRef.current;
    const showRef =
      globalContext.language === 'es' ? esRef.current : enRef.current;
    // Ordenamos segun corresponda
    showRef.style.order = 1;
    hideRef.style.order = 2;

    let scale = isOpen ? 1 : 0.5;
    let alpha = isOpen ? 1 : 0;
    let display = isOpen ? 'block' : 'none';

    gsap.to(hideRef, time, {
      autoAlpha: alpha,
      display,
    });
    gsap.to(selectorRef.current, time, { scale: scale });
    // eslint-disable-next-line
  }, [isOpen]);

  const onClickOption = (iso) => {
    globalContext.setLanguage(iso);
    setIsOpen(!isOpen);
  };

  return (
    <ul
      className={`language-selector${isOpen ? ' language-selector__open' : ''}${
        className ? ' ' + className : ''
      }`}
      ref={selectorRef}
    >
      <LanguageOption
        image={es}
        name='Español'
        iso='es'
        isSelected={globalContext.language === 'es'}
        onClick={onClickOption}
        ref={esRef}
      />
      <LanguageOption
        image={en}
        name='English'
        iso='en'
        isSelected={globalContext.language === 'en'}
        onClick={onClickOption}
        ref={enRef}
      />
    </ul>
  );
}
