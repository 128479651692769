import React, { createContext, useState } from "react";

export const HeaderContext = createContext();

const HeaderContextProvider = ({ children }) => {
  const [isHeaderSmall, setHeaderSmall] = useState(false);
  const [isLanguageShown, setLaguageShown] = useState(false);
  const [isHorizontalLogo, setHorizontalLogo] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);

  return (
    <HeaderContext.Provider
      value={{
        isHeaderSmall,
        setHeaderSmall,
        isLanguageShown,
        setLaguageShown,
        isHorizontalLogo,
        setHorizontalLogo,
        hasShadow,
        setHasShadow,
        backgroundImage,
        setBackgroundImage,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderContextProvider;
