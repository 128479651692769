import React, { useRef, useEffect, useContext, useState } from 'react';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { HeaderContext } from '../../context/HeaderContext';
import { GlobalContext } from '../../context/GlobalContext';
import { Link } from 'react-router-dom';
import { sanitizeWithAllowTags } from '../../utils/clearWpParagraph';

const startState = { autoAlpha: 0, y: 50 };

const Instrucciones = ({ show, data }) => {
  //refs
  const nodeRef = useRef(null);

  // context & state
  const globalContext = useContext(GlobalContext);
  const headerContext = useContext(HeaderContext);
  const [currentTexts, setCurrentTexts] = useState(data);

  // Setup header
  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(true);
      headerContext.setLaguageShown(true);
      headerContext.setHorizontalLogo(true);
      headerContext.setHasShadow(false);
      headerContext.setBackgroundImage(null);
      globalContext.setAppScroll('app--scroll');
    }
    // Ponemos el logo de telofonica en modo sticky
    const className = 'app--scroll';
    const app = document.querySelector('.app');
    app.classList.add(className);
    return () => {
      app.classList.remove(className);
    };
    // eslint-disable-next-line
  }, [show]);

  // i10n texts
  useEffect(() => {
    if (data) {
      setCurrentTexts(
        globalContext.language === 'es' ? data : data.translation
      );
    }
  }, [data, globalContext.language]);

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <section ref={nodeRef} className='page page--instrucciones'>
        <div className='subtitle__full-center'>
          <h2 className='subtitle'>{currentTexts.title}</h2>
        </div>
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeWithAllowTags(currentTexts.content),
          }}
        ></span>
        <div className='navigation modal__navigation'>
          <Link
            className='navigation__button navigation__button--next'
            onClick={() => {}}
            to='/seleccion-sala'
          >
            {globalContext.getStaticTextByKey('begin')}
          </Link>
        </div>
      </section>
    </Transition>
  );
};

export default Instrucciones;
