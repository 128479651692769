import React from 'react';
import ID from '../utils/id';
import DOMPurify from 'dompurify';

function clearWpParagraph(text, className = 'content__paragraph') {
  return text.split('<p>').map((paragraphText) => {
    const ptext = paragraphText.replace('</p>', '');
    return (
      <p key={ID()} className={`${className}`}>
        {ptext}
      </p>
    );
  });
}

export default clearWpParagraph;

export const replacePTags = (string) => string.replace(/<\/?p[^>]*>/g, '');

// Una alternaiva a limpiar el codigo para usar esta version necesitamos emplear insertDangerouly html, esta libreria deberia proteger contra Xss.

export const sanitizeWithAllowTags = (
  string,
  allowebTags = [
    'b',
    'strong',
    'i',
    'ul',
    'li',
    'p',
    'a',
    'em',
    'ol',
    'img',
    'h2',
    'sup',
  ]
) =>
  DOMPurify.sanitize(string, {
    ALLOWED_TAGS: allowebTags,
  });
