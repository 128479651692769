import React, { useRef, useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { HeaderContext } from '../../context/HeaderContext';
import { sanitizeWithAllowTags } from '../../utils/clearWpParagraph';
// Images
import es from '../../assets/images/bandera-espa-a.svg';
import en from '../../assets/images/bandera-inglesa.svg';
import teatro from '../../assets/images/teatro.png';
import teatro2x from '../../assets/images/teatro@2x.png';
import teatro3x from '../../assets/images/teatro@3x.png';
import { Link } from "react-router-dom";

const startState = { autoAlpha: 0, y: 0 };

export default function Languaje({ show, data, onNext }) {
  //refs
  const languageRef = useRef(null);
  const nodeRef = useRef(null);

  // context & state
  const globalContext = useContext(GlobalContext);
  const headerContext = useContext(HeaderContext);
  const [currentTexts, setCurrentTexts] = useState(data);

  // Setup header
  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(false);
      headerContext.setLaguageShown(false);
      headerContext.setHorizontalLogo(false);
      headerContext.setHasShadow(false);
      headerContext.setBackgroundImage(null);
      globalContext.setAppScroll(false);
      globalContext.setAppIsSalas(false);
    }
    // eslint-disable-next-line
  }, [show]);

  // i10n texts
  useEffect(() => {
    if (data) {
      setCurrentTexts(
        globalContext.language === 'es' ? data : data.translation
      );
    }
  }, [data, globalContext.language]);

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <section ref={nodeRef} className='page page--select-language'>
        <div ref={languageRef} className='language'>
          <div className='language__text'>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeWithAllowTags(currentTexts.content),
              }}
            ></span>
          </div>
          <ul className='language__selector'>
            <li className='language__option'>
              <button
                className={`language__link ${
                  globalContext.language === 'es'
                    ? 'language__link--selected'
                    : ''
                } `}
                onClick={(ev) => {
                  globalContext.setLanguage('es');
                }}
              >
                <img src={es} alt='' className='language__image' />
              </button>
            </li>
            <li className='language__option'>
              <button
                className={`language__link ${
                  globalContext.language === 'en'
                    ? 'language__link--selected'
                    : ''
                } `}
                onClick={(ev) => {
                  globalContext.setLanguage('en');
                }}
              >
                <img src={en} alt='' className='language__image' />
              </button>
            </li>
          </ul>
          <div className='language__navigation navigation'>
           <Link to="/instrucciones" className="navigation__button">
              {globalContext.getStaticTextByKey("next")}
            </Link>
          </div>
        </div>
        <footer className='footer'>
          <img
            src={teatro}
            srcSet={`${teatro2x} 2x,
              ${teatro3x} 3x`}
            className='teatro'
            alt='imagen del museo'
          />
          <div className='footer__color-band'></div>
        </footer>
      </section>
    </Transition>
  );
}
